import { ContentCopy } from "@mui/icons-material";
import { Autocomplete, TextField, Tooltip, Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { LanguageDetails } from "../views/Language/LanguageInterfaces";

export interface PickerProps {
  type: string;
  fieldValue: string | undefined;
  fieldValueSetter: Dispatch<SetStateAction<LanguageDetails | undefined>>;
  index?: number;
  options: PickerObject[] | undefined;
  autopopulateValues: (string | undefined)[];
  editingIndicator: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

export interface PickerObject {
  name: string;
  id: string;
}

const PickerWithSuggestion = ({
  type,
  fieldValue,
  fieldValueSetter,
  options,
  autopopulateValues,
  editingIndicator,
  disabled,
}: PickerProps) => {
  const parseCountries = (countryCode: string) => {
    let pickerObject: PickerObject = {} as PickerObject;

    const match = options?.find(
      (element: PickerObject) => element.id === countryCode,
    );
    if (match) {
      pickerObject = { ...match };
    }

    return pickerObject;
  };

  const handleInputChange = (value: PickerObject | null) => {
    editingIndicator(true);

    // fieldValueSetter((prevState: LanguageDetails | Media[] | undefined) => {
    fieldValueSetter((prevState: LanguageDetails | undefined) => {
      if (!prevState) return prevState;

      if (type === "language.country" || type === "addLanguage.country") {
        if ("fcbh" in prevState) {
          // prevState is LanguageDetails
          const existingFieldValue = {
            ...prevState,
            fcbh: { ...prevState.fcbh, countries: value?.id ?? "" },
          };
          return existingFieldValue;
        }
      }

      return prevState;
    });
  };

  const autopopulateValue = () => {
    if (type === "language.country") {
      editingIndicator(true);
      fieldValueSetter((prevState) => {
        if (!prevState) return prevState;

        if ("fcbh" in prevState) {
          const existingFieldValue = { ...prevState };
          const autopopulateValues = getAutopopulateValue();
          const codeList: string[] = [];
          autopopulateValues
            ?.split(",")
            .forEach((autopopulateValue: string) => {
              const pickerObjectMatch = options?.find(
                (element: PickerObject) =>
                  element.name.toLowerCase() ===
                  autopopulateValue.trim().toLowerCase(),
              );
              if (pickerObjectMatch) {
                codeList.push(pickerObjectMatch.id);
              }
            });

          existingFieldValue.fcbh.countries = codeList.join(";");
          return existingFieldValue;
        }

        return prevState;
      });
    }
  };

  const getAutopopulateValue = () => {
    if (!autopopulateValues.length) {
      return;
    } else {
      return autopopulateValues.find((element) => !!element);
    }
  };

  const autoCompleteValue = parseCountries(fieldValue ?? "");

  return (
    <>
      <Autocomplete
        autoHighlight
        onChange={(_, value) => handleInputChange(value)}
        value={autoCompleteValue?.id ? autoCompleteValue : null}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options ?? []}
        sx={{ minWidth: "200px" }}
        size="small"
        fullWidth
        renderInput={(params) => <TextField {...params} />}
        disabled={disabled}
      />
      {getAutopopulateValue() && !fieldValue && (
        <Tooltip title="Populate value from reference data.">
          <Button
            color="secondary"
            size="small"
            onClick={autopopulateValue}
            startIcon={<ContentCopy />}
          >
            {"Use '" + getAutopopulateValue() + "'"}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default PickerWithSuggestion;
